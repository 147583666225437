import emailjs from 'emailjs-com';
import { modal as Modal } from 'tingle.js';

import {
  getCookie,
  getCurrentUrl,
  getIP,
  resetForm,
} from '/src/scripts/utils/common';

import Validator from '/src/scripts/es-modules/validator';
import {
  hidePreloader,
  showPreloader,
} from '/src/scripts/es-modules/preloader';

emailjs.init('user_eYZMbSfh5QY63OkYXzPni');

const formEl = document.getElementById('feedback');

const popup = new Modal({
  cssClass: ['alert'],
  closeMethods: ['button', 'escape'],
});

function showAlert() {
  popup
    .setContent(
      `
      <div id="form-sent">
        <h3>Thank you!</h3>
        <p>We're reading you message...</p>
        <button class="btn js-close-popup" type="button">Ok</button>
      </div>
    `,
    )
    .open();

  document
    .querySelector('.js-close-popup')
    .addEventListener('click', () => popup.close(), { once: true });
}

document.addEventListener('DOMContentLoaded', async () => {
  const clientIp = await getIP();
  const clientId = getCookie('_ga')?.slice(6);

  const currentUrl = getCurrentUrl();

  let initialUrl = localStorage.getItem('initial_url');
  let referrerUrl = localStorage.getItem('referrer_url');

  const { referrer } = document;

  if (!referrer.includes(location.host)) {
    initialUrl = currentUrl;
    referrerUrl = referrer || currentUrl;
  } else if (!initialUrl) {
    initialUrl = currentUrl;
    referrerUrl = currentUrl;
  }

  localStorage.setItem('initial_url', initialUrl);
  localStorage.setItem('referrer_url', referrerUrl);

  new Validator(formEl, function (form, formData) {
    const data = {
      client_ip: clientIp,
      client_id: clientId,

      current_url: currentUrl,
      initial_url: initialUrl,
      referrer_url: referrerUrl,

      phone: formData.phone,
      message: formData.message,
      company: formData.company,
      from_app: formData.from_app,
      from_name: formData.name,
      from_mail: formData.email,
    };

    showPreloader();

    emailjs
      .send('service_ou4lyil', 'template_e3ucd6s', data)
      .then(() => {
        window.dataLayer.push({
          ...data,
          event: 'onSubmit',
          action: 'Form Submitted',
          category: 'Contact form',
        });

        resetForm(form);
        hidePreloader();
        showAlert();
      })
      .catch(() => {
        hidePreloader();
      });
  });
});

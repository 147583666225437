import { addEventListeners } from '/src/scripts/utils/common';
import { slideDown, slideUp } from '/src/scripts/utils/slide-effect';

const headerEl = document.querySelector('.header');
const navbarEl = document.querySelector('.navbar');

function closeMenu() {
  navbarEl.classList.remove('navbar_open');
  headerEl.classList.remove('header_menu-open');
  document.body.classList.remove('no-scrollbar');
}

window.addEventListener('resize', closeMenu);

document.querySelector('.js-switch-menu').addEventListener('click', () => {
  if (headerEl.classList.contains('header_menu-open')) {
    closeMenu();
  } else {
    if (window.overlayScrollbar) {
      window.overlayScrollbar.scroll(0);
    } else {
      window.scroll(0, 0);
    }

    navbarEl.classList.add('navbar_open');
    headerEl.classList.add('header_menu-open');
    document.body.classList.add('no-scrollbar');
  }
});

addEventListeners('.js-toggle-submenu', 'click', (e) => {
  if (window.innerWidth <= 1024) {
    e.preventDefault();

    const parent = e.target.parentElement;
    const dropdown = e.target.nextElementSibling;

    if (parent.classList.contains('navbar__item_open')) {
      parent.classList.remove('navbar__item_open');
      slideUp(dropdown);
    } else {
      parent.classList.add('navbar__item_open');
      slideDown(dropdown);
    }
  }
});

export function hasTouch() {
  return 'ontouchstart' in window;
}

export function parseSrc(src) {
  return src.split(/(.png|.jpe?g)$/);
}

export function loop(selector, callback) {
  let index;
  let elements =
    typeof selector === 'string'
      ? document.querySelectorAll(selector)
      : selector;

  for (index = 0; index < elements.length; index++) {
    callback(elements[index], index);
  }
}

export function getIndex(element) {
  let parent = element.parentNode;
  return Array.prototype.indexOf.call(parent.children, element);
}

export function resetForm(form) {
  let fields = form.querySelectorAll('input, textarea');

  removeClassName(fields, 'invalid');

  form.reset();
}

export function addClassNames(selector, classNames) {
  const elements =
    typeof selector !== 'object'
      ? document.querySelectorAll(selector)
      : selector;

  loop(elements, function (element) {
    element.classList.add(...classNames);
  });
}

export function removeClassName(selector, classNames) {
  const elements =
    typeof selector !== 'object'
      ? document.querySelectorAll(selector)
      : selector;

  loop(elements, function (element) {
    element.classList.remove(...classNames);
  });
}

export function addEventListeners(selector, eventName, callback, options) {
  const elements =
    typeof selector !== 'object'
      ? document.querySelectorAll(selector)
      : selector;

  loop(elements, (element, index) => {
    element.addEventListener(
      eventName,
      (e) => {
        callback(e, element, index);
      },
      options,
    );
  });
}

export function addEventsListener(selector, eventList, callback) {
  const element =
    typeof selector !== 'object' ? document.querySelector(selector) : selector;

  if (!element) return true;

  loop(eventList, (eventName) => {
    element.addEventListener(eventName, (e) => {
      callback(e, element);
    });
  });
}

export async function getIP() {
  try {
    const response = await fetch('https://api.ipify.org');
    return await response.text();
  } catch (e) {
    return undefined;
  }
}

export function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
        '=([^;]*)',
    ),
  );

  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function getCurrentUrl() {
  const { origin, pathname } = window.location;

  return `${origin}${pathname}`;
}

export function getIdFromInnerText(elm) {
  return elm.innerText
    .toLowerCase()
    .replace(/([-:;]|&(.*);)/g, '')
    .replace(/\s+/g, '-');
}

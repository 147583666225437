import { easeOutQuart } from './easings';

function scrollIt(destination, duration = 400, callback) {
  const start = window.scrollY;
  const startTime = performance.now();

  const windowHeight = window.innerHeight;
  const documentHeight = Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.clientHeight,
    document.documentElement.scrollHeight,
    document.documentElement.offsetHeight,
  );

  const destinationOffset =
    typeof destination === 'number' ? destination : destination.offsetTop;
  const destinationOffsetToScroll = Math.round(
    documentHeight - destinationOffset < windowHeight
      ? documentHeight - windowHeight
      : destinationOffset,
  );

  // function resolves position of a window and moves to exact amount of pixels
  // resolved by calculating delta and timing function chosen by user
  function scroll() {
    const now = performance.now();
    const time = Math.min(1, (now - startTime) / duration);
    const timeFunction = easeOutQuart(time);
    const y = Math.ceil(
      timeFunction * (destinationOffsetToScroll - start) + start,
    );

    window.scroll(0, y);

    // Stop requesting animation when window reached its destination
    // And run a callback function
    if (window.scrollY === destinationOffsetToScroll) {
      if (callback) {
        callback();
      }
      return;
    }

    requestAnimationFrame(scroll);
  }

  scroll();
}

export default scrollIt;

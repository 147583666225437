import { slideDown, slideUp } from '../utils/slide-effect';

const tabs = document.querySelectorAll('.js-tabs');

tabs.forEach((box) => {
  const menu = box.querySelector('.js-tabs-menu');
  const links = box.querySelectorAll('.js-tabs-link');
  const items = box.querySelectorAll('.js-tabs-item');

  const { itemActiveClass } = box.dataset;
  const { linkActiveClass } = menu.dataset;

  links.forEach((link, index) => {
    link.addEventListener('click', () => {
      if (link.classList.contains(linkActiveClass)) return;

      const thisItem = items[index];
      const prevItem = box.querySelector(`.${itemActiveClass}`);
      const prevLink = menu.querySelector(`.${linkActiveClass}`);

      link.classList.add(linkActiveClass);
      prevLink.classList.remove(linkActiveClass);

      slideUp(prevItem);
      prevItem.classList.remove(itemActiveClass);

      slideDown(thisItem);
      thisItem.classList.add(itemActiveClass);
    });
  });
});

let resizeTimeout;

window.addEventListener('resize', () => {
  document.body.classList.add('no-transitions');

  clearTimeout(resizeTimeout);
  resizeTimeout = setTimeout(() => {
    document.body.classList.remove('no-transitions');
  }, 100);
});

import { loop, addEventsListener } from '../utils/common';

export default class Validator {
  classNameError = 'invalid';

  events = ['keyup', 'change'];

  excludedKeys = [16, 17, 18, 20, 35, 36, 37, 38, 39, 40, 45, 144, 225];

  messages = {
    required: 'This field is required.',
    tel: 'Please enter a valid phone number.',
    email: 'Please enter a valid email address.',
    minlength: 'Please enter at least {0} characters.',
  };

  constructor(form, callback) {
    if (form) {
      let that = this;

      that.form = form;
      that.callback = callback;

      that.fields = form.querySelectorAll('[required], [pattern]');

      that.addFormListener();
      that.addFieldsListener();
    }
  }

  checkForm() {
    let that = this;

    loop(that.fields, (element) => {
      that.checkElement(element);
    });
  }

  checkElement(element) {
    let that = this;
    let isValid = element.checkValidity();

    let elMessage = element
      .closest('.js-field-box')
      .querySelector('.js-field-error');

    if (isValid) {
      if (elMessage) {
        elMessage.innerHTML = '';
      }

      element.classList.remove(that.classNameError);
    } else {
      if (elMessage) {
        let value = element.value;
        let minlength = element.getAttribute('minlength');
        let message;

        if (!value.length) {
          message = that.messages.required;
        } else if (value.length < minlength) {
          message = that.messages.minlength.replace('{0}', minlength);
        } else {
          let type = element.getAttribute('type');
          message = that.messages[type];
        }

        elMessage.innerHTML = message;
      }

      element.classList.add(that.classNameError);
    }
  }

  addFormListener() {
    let that = this;
    let form = that.form;

    form.addEventListener('submit', (e) => {
      e.preventDefault();

      let isValid = form.checkValidity();

      if (isValid) {
        let formData = Object.fromEntries(new FormData(form));

        that.callback(form, formData);
      } else {
        that.checkForm();
      }
    });
  }

  addFieldsListener() {
    let that = this;

    loop(that.fields, (element) => {
      addEventsListener(element, that.events, (e) => {
        let isEventKeyup = e.type === 'keyup';
        let isExcludedKeys = that.excludedKeys.includes(e.keyCode);

        if (isEventKeyup) {
          let hasClassError = element.classList.contains(that.classNameError);

          if (!isExcludedKeys && hasClassError) {
            clearTimeout(that.fieldTimeout);
            that.fieldTimeout = setTimeout(() => {
              that.checkElement(element);
            }, 300);
          }
        } else {
          that.checkElement(element);
        }
      });
    });
  }
}

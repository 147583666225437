import OverlayScrollbars from 'overlayscrollbars';

import { addEventListeners, hasTouch } from '../utils/common';
import scrollIt from '../utils/scroll-it';

document.addEventListener('DOMContentLoaded', () => {
  if (!hasTouch()) {
    window.overlayScrollbar = OverlayScrollbars(document.body, {
      className: 'os-theme-dark',
      callbacks: {
        onInitialized() {
          this.getElements('viewport').classList.add('js-viewport');
        },
      },
      overflowBehavior: {
        x: 'hidden',
      },
    });
  }

  window.scrollToElement = (selector) => {
    const element = document.querySelector(selector);

    if (element) {
      const { overlayScrollbar } = window;

      if (overlayScrollbar) {
        overlayScrollbar.scroll(
          {
            el: element,
            block: { y: 'begin' },
          },
          600,
          'easeOutQuart',
        );
      } else {
        scrollIt(element.offsetTop, 600);
      }
    }
  };

  window.scrollToPosition = (position) => {
    const { overlayScrollbar } = window;

    if (overlayScrollbar) {
      overlayScrollbar.scroll({ y: position }, 600, 'easeOutQuart');
    } else {
      scrollIt(position, 600);
    }
  };

  window.scrollEmitterElement = window.overlayScrollbar
    ? document.querySelector('.js-viewport')
    : window;

  if (location.hash) {
    window.scrollToElement(location.hash);
  }

  addEventListeners('.js-scroll-to', 'click', (e, elm) => {
    e.preventDefault();

    const id = elm
      .getAttribute(elm.href ? 'href' : 'data-target')
      .replace('#', '');

    window.scrollToElement(`#${id}, [data-id="${id}"]`);
  });
});

import './es-modules/preloader';
import './es-modules/scrollbar';
import './es-modules/splitting';
import './es-modules/scrollout';

import './es-modules/no-resize-transitions';

import '../components/globals/lift/lift';
import '../components/globals/navbar/navbar';
import '../components/globals/cookies/cookies';

window.history.scrollRestoration = 'manual';
window.dataLayer = window.dataLayer || [];

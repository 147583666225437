document.addEventListener('DOMContentLoaded', () => {
  const btn = document.querySelector('.lift__btn');

  if (btn) {
    window.scrollEmitterElement.addEventListener('scroll', () => {
      const scrollTop = window.overlayScrollbar
        ? window.overlayScrollbar.scroll().position.y
        : window.scrollY;

      if (scrollTop >= window.innerHeight * 0.5) {
        btn.classList.add('lift__btn_show');
      } else {
        btn.classList.remove('lift__btn_show');
      }
    });

    btn.addEventListener('click', () => window.scrollToPosition(0));
  }
});

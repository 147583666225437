export function showPreloader() {
  document.body.classList.add('page_loading');
}

export function hidePreloader() {
  document.body.classList.remove('page_loading');
}

document.addEventListener('DOMContentLoaded', () => {
  setTimeout(hidePreloader, 300);
});

import Columns from 'columns.js';

const container = document.querySelector('.posts__body');

if (container) {
  const is3Col = container.classList.contains('posts__body_3col');

  const grid = new Columns(container, {
    columns: 1,
    algorithm: 'chronological',
    column_class: 'posts__column',
    breakpoints: {
      768: 2,
      ...(is3Col ? { 1280: 3 } : {}),
    },
  });

  function handleChange(e) {
    console.log(e.matches);
    if (e.matches) {
      grid.render();
    }
  }

  window
    .matchMedia('(min-width: 1281px)')
    .addEventListener('change', handleChange);
  window
    .matchMedia('(min-width: 769px) and (max-width: 1280px)')
    .addEventListener('change', handleChange);
  window
    .matchMedia('(min-width: 360px) and (max-width: 768px)')
    .addEventListener('change', handleChange);
}

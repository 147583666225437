import Splitting from 'splitting';

Splitting({
  by: 'chars',
  target: '.js-chars',
});

Splitting({
  by: 'words',
  target: '.js-words',
});

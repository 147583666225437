export function slideUp(target, callback, duration = 400) {
  target.style.display = 'block';
  target.style.transitionDelay = '0s';
  target.style.transitionProperty = 'all';
  target.style.transitionDuration = duration + 'ms';
  target.style.height = target.offsetHeight + 'px';

  target.offsetHeight;

  target.style.height = 0;
  target.style.opacity = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.overflow = 'hidden';

  clearTimeout(target.dataset.timeoutId);
  target.dataset.timeoutId = setTimeout(() => {
    target.style.display = '';
    target.style.removeProperty('height');
    target.style.removeProperty('opacity');
    target.style.removeProperty('overflow');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('transition-delay');
    target.style.removeProperty('transition-duration');
    target.style.removeProperty('transition-property');

    if (typeof callback === 'function') {
      callback();
    }
  }, duration);
}

export function slideDown(target, callback, duration = 400) {
  target.style.transitionProperty = 'none';
  target.style.removeProperty('display');
  let display = window.getComputedStyle(target).display;

  if (display === 'none') display = 'block';

  target.style.display = display;

  let height = target.offsetHeight;

  target.style.height = 0;
  target.style.opacity = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.overflow = 'hidden';

  target.offsetHeight;

  target.style.transitionDelay = '0s';
  target.style.transitionProperty = 'all';
  target.style.transitionDuration = duration + 'ms';
  target.style.height = height + 'px';

  target.style.removeProperty('opacity');
  target.style.removeProperty('margin-top');
  target.style.removeProperty('margin-bottom');
  target.style.removeProperty('padding-top');
  target.style.removeProperty('padding-bottom');

  clearTimeout(target.dataset.timeoutId);
  target.dataset.timeoutId = setTimeout(() => {
    target.style.removeProperty('height');
    target.style.removeProperty('overflow');
    target.style.removeProperty('transition-delay');
    target.style.removeProperty('transition-duration');
    target.style.removeProperty('transition-property');

    if (typeof callback === 'function') {
      callback();
    }
  }, duration);
}

export function slideToggle(target, duration = 400) {
  if (getComputedStyle(target).display === 'none') {
    return slideDown(target, duration);
  } else {
    return slideUp(target, duration);
  }
}

import ScrollOut from 'scroll-out';

document.addEventListener('DOMContentLoaded', () => {
  setTimeout(() => {
    ScrollOut({
      once: true,
      targets: '.js-chars, .word',
      cssProps: { index: true },
      scrollingElement: window.overlayScrollbar
        ? window.scrollEmitterElement
        : undefined,
    });

    window.scrollOut = ScrollOut({
      once: true,
      targets: '.js-wow',
      cssProps: { index: true },
      scrollingElement: window.overlayScrollbar
        ? window.scrollEmitterElement
        : undefined,
      onShown: (el) => {
        const style = getComputedStyle(el);
        const delay = Number.parseFloat(style.transitionDelay.replace('s', ''));
        const duration = Number.parseFloat(
          style.transitionDuration.replace('s', ''),
        );

        setTimeout(() => {
          el.classList.add('animated');
          el.classList.remove('js-wow');
          el.removeAttribute('data-scroll');
        }, (delay + duration) * 1000);

        setTimeout(() => {
          el.querySelectorAll('.word').forEach((item) => {
            item.removeAttribute('data-scroll');
          });
        }, 300);
      },
    });

    setTimeout(() => {}, 200);
  }, 400);
});

document.addEventListener('DOMContentLoaded', () => {
  const cookiesEl = document.querySelector('.cookies');

  if (localStorage.getItem('cookies')) {
    cookiesEl.remove();
  } else {
    cookiesEl.classList.add('cookies_show');

    document.querySelector('.cookies__btn').addEventListener(
      'click',
      () => {
        cookiesEl.classList.remove('cookies_show');
        localStorage.setItem('cookies', true);

        setTimeout(() => {
          cookiesEl.remove();
        }, 600);
      },
      { once: true },
    );
  }
});
